import React from 'react'

const HeaderGeneric = (props) => (
    <header id="header">
        <h1>You want more info???</h1>
        <p>Well here it is! :)</p>
    </header>
)

export default HeaderGeneric
